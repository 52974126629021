<template>
     <div class="row">
        <div class="col-md-8">
            <h5 class="font-weight-bold">Rate Designer</h5>
            <div>
                <b-input-group>
                    <b-form-rating inline v-model="data.rating"  stars="5" color="#ff8800"></b-form-rating>
                    <b-input-group-append>
                        <b-input-group-text class="justify-content-center" style="min-width: 3em;">
                        {{ data.rating }}
                        </b-input-group-text>
                    </b-input-group-append>
                </b-input-group>
            </div>
            <div class="mt-3">
                <div class="w-100">
                    <textarea  v-model="data.content" class="form-control mb-0"
                    rows="4" placeholder="Share your experience" v-validate="'required|max:10000'" name="review"></textarea>
                </div>
            </div>
        </div>
        <div class="col-md-4 d-flex align-items-end">
            <div class="text-center">
                <button type="button" @click.prevent="submitReview" class="btn btn-primary mt-2">
                    <i class="mdi mdi-content-save"></i> Submit
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data:{
                rating: null,
                content: ""
            },
        }
    },
    props:{
        deliveryId:{
            type: Number,
            required: true
        }
    },
    methods:{
        submitReview(){
            this.$validator.validateAll().then(result => {
                if (!result || !this.data.rating) { this.alertError("Form Not Valid"); return;}
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/deliveries/${this.deliveryId}/review`,formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("orderList/UPDATE_DELIVERY", response.data.data.delivery)
                        this.resetForm();
                    }
                })
            });
        },
        resetForm() {
            this.data = {
                rating: null,
                content: ""
            },
            this.$validator.reset()
        },
    },
}
</script>
