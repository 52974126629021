
<template>
  <div>
     <vue-dropzone id="fileUploadDrop" ref="fileUploadDrop"
      :use-custom-slot="true">
      <div class="needsclick m-0">
        <h4>Drop files here or click to upload.</h4>
        <span class="text-muted font-13">
          (Each File should not be more than 10MB)
        </span>
      </div>
    </vue-dropzone>
    <div class="mt-3">
      <b-button variant="primary" @click.prevent="sendAttachment()" type="button">Upload</b-button>
    </div>
</div>
</template>

<script>
import VueDropzone from '@/components/VueDropzone.vue';
export default {
  components:{
    VueDropzone
  },
  name: "upload-files",
  methods: {
    sendAttachment() {
      this.$validator.validateAll().then(result => {
        if(!this.collectFiles().length){ this.alertError("Please a file is required"); return;}
        this.$emit("onUpload",this.collectFiles())
      })
    },
    collectFiles(){
      let arrafile = [];
      if(this.$refs.fileUploadDrop){
        arrafile = [...this.$refs.fileUploadDrop.getAcceptedFiles()];
      }
      return arrafile
    },
  }
}

</script>

