<template>
  <div class="order-brief">
    <feature-list :cart="cart" class="mb-4" />
    <addons :addons="cart.addons" class="mb-4" />
    <requirements />
  </div>
</template>

<script>
import FeatureList from "./FeatureList.vue"
import Requirements from "./Requirements.vue"
import Addons from "./Addons.vue"

export default {
  components:{
    FeatureList,
    Addons,
    Requirements
  },
  computed:{
    order(){
      return this.$store.state.orderList.order
    },
    cart(){
      return this.order.cart
    },
  },
}

</script>

