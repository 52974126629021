<template>
  <div class="order-downloads">
    <b-alert :show="!isFinished" variant="warning">
      <p class="mb-0">
        <i class="mdi mdi-block-helper mr-2"></i> <span>Expect final files when you choose a winner.</span>
      </p>
    </b-alert>
    <div class="pt-3" v-if="isFinished && winner">
        <delivery-files :files="winner.files" />
    </div>
  </div>
</template>
<script>
import DeliveryFiles from "./DeliveryFiles.vue"

export default {
  components: {
      DeliveryFiles
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    winner(){
      return this.order.deliveries.find(d => d.status == "WINNER")
    },
    isFinished(){
      return this.order.stage == "finished";
    },
  },
}
</script>
