<template>
    <div class="row px-2">
        <div class="col-6 g-2" v-for="(file, fIndex) in files" :key="fIndex">
            <div class="card my-0 overflow-hidden shadow-none border text-left">
                <div class="" v-if="isImageFile(file.extension)">
                    <img @click="() => showImg(fIndex)" class="cursor-pointer img-fluid" :src="absoluteUrl(file.path)" :alt="file.name">
                </div>
                <div class="p-2">
                    <div class="row g-0 align-items-center">
                        <div class="col-auto d-none d-sm-block pr-0">
                            <span class="badge mr-2 badge-primary font-8 p-1 rounded text-uppercase">{{file.extension}}</span>
                        </div>
                        <div class="col">
                            <a :href="absoluteUrl(`/download?file_path=${file.path}`)"
                                :download="file.name" class="text-muted">
                                <span class="d-none d-sm-inline text-muted">{{file.name}}</span>
                                <p class="mb-0 d-flex font-12"><i class="mdi mdi-download"></i>{{file.size}}</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "message-files",
    props: {
        files: {
            type: Array,
            required: true,
        },
    },
    methods:{
        showImg(imgIndex){
            this.$emit("showImg", {files: this.files, index: imgIndex})
        }
    }
}
</script>
