<template>
<div class="order-notes">
  <h4>Do you have more information to send across to the designers?</h4>
  <p class="text-success">Write extra note and save. You can provide more description to your project.</p>
  <quill-editor
    ref="orderQuillEditor"
    v-model="content"
    :options="editorOption"
  />
  <button @click="updateNote()" class="mt-3 mb-2 btn btn-primary">Save</button>
  <p class=""><small><i> Note: The saved note shall be available to all the designers in this project </i></small></p>
</div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
      quillEditor
  },
  data () {
    return {
      content: '',
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            // [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
            // ['link']
          ],
        }
      },
    }
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    editor() {
      return this.$refs.orderQuillEditor.quill
    }
  },
  methods: {
    updateNote(){
      let formData = new FormData()
      formData.append('data', JSON.stringify({description: this.content}))
      this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/orders/${this.order.id}/save-note`,formData)
        .then(response => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$store.dispatch("orderList/fetchOrder",this.order.id)
          }
      })
    }
  },
  mounted(){
    this.content = this.order.description
  }
}
</script>

<style>
.order-notes .ql-editor{
    min-height: 12rem;
}
</style>